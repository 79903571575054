body {
  margin: 0;
  font-family: 'Oswald', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #002a1e;
  background-size: 50%;
  background-image: url('https://cdn.diamondhands.io/rocket.png');
  animation: slide 20s linear infinite;
}

@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -400px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input,
textarea {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgray;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  font-family: inherit;
}

select {
  appearance: none;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 10px;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
}

button {
  font-family: inherit;
  padding: 7px 15px;
  border: none;
  border-radius: 5px;
  background-color: #33554b;
  color: white;
  font-size: 18px;
  font-weight: bold;
  min-width: 90px;
  transition: 0.2s;
  cursor: pointer;
  text-transform: uppercase;
}

button:hover {
  background-color: #233e36;
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.link-btn {
  font-family: inherit;
  padding: 7px 15px;
  border: none;
  border-radius: 5px;
  background-color: #33554b;
  font-weight: bold;
  min-width: 90px;
  transition: 0.2s;
  cursor: pointer;
  transition: 0.2s;
}

.link-btn:hover {
  background-color: #233e36;
}
